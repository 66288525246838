import React from 'react';
import useActiveTimeTracker from '../hooks/useActiveTimeTracker';

const TimeTracker = ({ children }) => {
  const totalTime = useActiveTimeTracker();

  return (
    <div>
      {/* Optional display for total active time */}
      {/* <p>Total Active Time Spent: {Math.round(totalTime / 1000)} seconds</p> */}
      {/* {children} */}
    </div>
  );
};

export default TimeTracker;
