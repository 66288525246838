// src/hooks/useActiveTimeTracker.js
import { useEffect, useRef, useState } from 'react';
import {
    getLocalUserdata,
} from "../services/auth/localStorageData"; // Adjust based on your new file location

const useActiveTimeTracker = () => {
    const [totalTime, setTotalTime] = useState(0);
    const startTimeRef = useRef(Date.now());
    const accumulatedTimeRef = useRef(0);
    const data = getLocalUserdata();
    const userId = data?.id; // Retrieve user ID from local storage

    useEffect(() => {
        const updateAccumulatedTime = () => {
            accumulatedTimeRef.current += Date.now() - startTimeRef.current;
            setTotalTime(accumulatedTimeRef.current);
        };

        const sendTimeData = async () => {
            const payload = { 
                session_id: userId, // Use the actual user ID from local storage
                time_spent: accumulatedTimeRef.current / 1000 // Convert to seconds
            };

            // Send data to the API
            try {
                const response = await fetch('https://neoestudio.net/api/track-time', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const data = await response.json();
                console.log('API response:', data); // Log the API response
            } catch (error) {
                console.error('Error sending time data:', error);
            }
        };

        const handleBeforeUnload = (event) => {
            updateAccumulatedTime(); // Update accumulated time
            
            // Send time data when leaving the page
            sendTimeData(); // Send time data before unloading

            // Show confirmation dialog
            const confirmationMessage = "Are you sure you want to leave? Your unsaved changes will be lost.";
            event.returnValue = confirmationMessage; // For modern browsers
            return confirmationMessage; // For older browsers
        };

        // Add event listeners
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Set interval to send data every 10 minutes (600000 ms)
        const interval = setInterval(() => {
            updateAccumulatedTime(); // Update accumulated time
            sendTimeData(); // Send time data every 10 minutes
        },180000); 

        return () => {
            updateAccumulatedTime();
            clearInterval(interval); // Clear the interval on unmount
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [userId]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTotalTime(accumulatedTimeRef.current + (Date.now() - startTimeRef.current));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return totalTime;
};

export default useActiveTimeTracker;
